body {
  margin: 0;
}

.Home {
  height: 100vh;
  width: 100vw;
}

.none {
  opacity: 0;
}
.active {
  opacity: 1;
}

.display_none{
  display: none;
}

.main1 {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

.main1.gray {
  background-color: #efefef;
}

.main1.green {
  background-color: #99fc79;
}

.main1.active {
  animation: fadein 1s forwards;
}

.main1.none {
  animation: fadeout 1s forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.main1 .header {
  position :fixed; 
  top:0; 
  left:0;
  z-index: 999;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5%;
  width: 89%;
}
.main1 .content1 {
  height: 50vh;
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
}

.main1 .content2 {
  height: 45vh;
  margin: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.main1 .sub1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
}

.main1 .sub2{
  position: relative;
}

.main1 .sub3{
  display: flex;
  align-items: flex-end;
  padding-bottom: 35px;
}

.main1 .sub4{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.main1 .subPart1{
  width: 70%;
}

.main1 .subPart2{
  width: 20%;
}

.main1 .subPart1.large{
  width: 75%;
}

.main1 .footer {
  margin: 0 5%;
  height: 5vh;
}

.home .img1 {
  width: 200px;
  position: absolute;
  bottom: 45px;
  left: 0;
}

.home .img2 {
  width: 171px;
  cursor: pointer;
}

.home .img3 {
  width: 442px;
}

.home .img4 {
  width: 327px;
  position: absolute;
  top: 0;
  left: 0;
}

.home .img5 {
  width: 226px;
  position: absolute;
  bottom: 120px;
  left: 0;
  cursor: pointer;
}

.home .img6 {
  width: 103px;
  position: absolute;
  bottom: 60px;
  left: 0;
}

.home .img7 {
  width: 300px;
}

.home .img8 {
  width: 100%;
}

.home .img9 {
  width: 90%;
  margin-bottom: 30px;
}

.home .img10 {
  width: 100%;
  margin-bottom: 50px;
}

.about .header {
  position :fixed; 
  top:0; 
  left:0;
  z-index: 999;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 5%;
  width: 89%;
}

.about .subPart1{
  width: 70%;
}

.about .subPart2{
  width: 20%;
}

.about .img1 {
  width: 171px;
  cursor: pointer;
}

.about .img2 {
  width: 300px;
}

.about .content img {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}